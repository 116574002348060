import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import HomePage from '../pages/homepage';
import MyAccount from '../pages/my-account';
import MyFavorites from '../pages/my-favorites';
import Cart from '../pages/cart';
import Autorization from '../pages/autorization';
import Checkout from '../pages/checkout'
import Сategory from '../pages/categories'
import Subcategory from '../pages/subcategories'
import Subsubcategory from '../pages/subsubcategories'
import ProductDetails from '../pages/product-details'
import Profile from '../pages/profile'
import NewsItemDetails from '../pages/news-item-details'
import News from '../pages/news' 
// import Delivery from '../pages/delivery'
import Contacts from '../pages/contacts'
import About from '../pages/about'
import { connect } from "react-redux";
import { menuData } from "../../actions/MenuDataActionCreators"
import { withRouter } from "react-router-dom";
import axiosFetch from "../../helpers/axios";
import CookieHelper from "../../helpers/cookie-helper";
import { setLoginAuth } from "../../actions/SetLoginAuthActionCreators"
import { setGlobalToken, getCartInfoFirstLoad, getCartData, getContacts, getFilters } from "../../actions/AddToCartActionCreator"
import { globalSettings } from "../../actions/GlobalSettingsActionCreator"
import {
    GET_CATEGORIES_LIST,
    GET_GLOBAL_SETTINGS
} from "../../helpers/constants";
import Test from '../test/test';
import SearchPage from '../pages/search-page';
import Cookies from 'universal-cookie'
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import "./styles.scss"

class App extends Component {

    state = {
        approveCookies: false,
    }

    componentDidMount() {
        const { setLoginAuth, getCartInfoFirstLoad, getCartData, getContacts, getFilters } = this.props
        const userName = CookieHelper("get", "userName");
        const userEmail = CookieHelper("get", "userEmail");

        const token = CookieHelper("get", "token");
        const generateToken = this.setToken()

        if (!token) {
            CookieHelper("set", "token", generateToken);
        }
        const loginDataObj = {
            name: userName,
            email: userEmail
        }
        getCartData(token)
        // console.log("123")
        setLoginAuth(loginDataObj)
        getCartInfoFirstLoad(token)
        getContacts()
        getFilters()

        this.getMenu()
        this.getGlobalSettings()

        const cookies = new Cookies()
        const approveCookies = cookies.get('approveCookies')
        this.setState({ approveCookies })
    }

    getGlobalSettings = () => {
        const { globalSettings } = this.props
        const settings = axiosFetch(GET_GLOBAL_SETTINGS);

        Promise.resolve(settings).then((arrList) => {
            globalSettings(arrList[0])
        });
    }

    getMenu = () => {
        const menuListResult = axiosFetch(GET_CATEGORIES_LIST);
        this.promiseResolved(menuListResult);
    }

    setToken = () => {
        var token = "";
        var possible = "AQWERTYUIOPLKJHGFDSAZXCVBNM!@#$%^&*()_+-=abcdefghijklmnopqrstuvwxyz1234567890";

        for (var i = 0; i < 50; i++)
            token += possible.charAt(Math.floor(Math.random() * possible.length));

        return token;
    }

    promiseResolved = (menuListResult) => {
        const { menuData } = this.props
        Promise.resolve(menuListResult).then((arrList) => {
            menuData(arrList)
        });
    }

    setCookie = () => {
        const cookies = new Cookies()
        cookies.set('approveCookies', true)
        this.setState({ approveCookies: true })
    }

    renderCookieModal = () => {
        const { t } = this.props
        return (
            <div className="coockie-modal-contaner">
                <div className="coockie-content-container">
                    <h3>{t('Cookie_permission')}</h3>
                    <button
                        type="button"
                        className="btn btn-primary icon"
                        onClick={() => this.setCookie()}
                    >
                        OK
                        </button>
                    <div className="coockie-modal-note">
                        {t('Cookie_note')}
                    </div>
                </div>
            </div>
        )
    }

    renderTehnicalModal = () => {
        return (
            <div className="under-construction">
                <div className="under-construction-container">
                    <h1>Проводятся технические работы </h1>
                </div>
            </div>
        )
    }

    render() {
        const { availableSite } = this.props
        const { approveCookies } = this.state
        return (
            <Router>
                {availableSite > 0 ? <Switch>
                    <Route path="/" exact component={HomePage} />
                    <Route path="/my-account" exact component={MyAccount} />
                    <Route path="/my-favorites" exact component={MyFavorites} />
                    <Route path="/cart" exact component={Cart} />
                    <Route path="/auth" exact component={Autorization} />
                    <Route path="/checkout" exact component={Checkout} />
                    <Route path="/category/:id" exact component={Сategory} />
                    <Route path="/advices/:id" exact component={NewsItemDetails} />
                    <Route path="/subcategory/:id" exact component={Subcategory} />
                    <Route path="/subsubcategory/:id" exact component={Subsubcategory} />
                    <Route path="/details/:productId" exact component={ProductDetails} />
                    <Route path="/profile" exact component={Profile} />
                    <Route path="/search" exact component={SearchPage} />
                    {/* <Route path="/delivery" exact component={Delivery} /> */}
                    <Route path="/contacts" exact component={Contacts} />
                    <Route path="/about" exact component={About} />
                    <Route path="/advices" exact component={News} />

                    <Route path="/test" exact component={Test} />

                    <Redirect to="/" />

                </Switch> :
                    <Switch>
                        <Route path="/" exact component={HomePage} />
                        <Redirect to="/" />

                    </Switch>
                }
                {!approveCookies && this.renderCookieModal()}
                {availableSite === 0 && this.renderTehnicalModal()}
            </Router>
        )
    };
}

const mapStateToProps = (state) => ({
    menu: state.MenuReducer,
    cartData: state.AddToCartReducer.cartData,
    availableSite: state.GlobalSettingsReducer.availableSite,
});

export default withRouter(compose(connect(mapStateToProps, {
    menuData,
    setLoginAuth,
    setGlobalToken,
    getCartInfoFirstLoad,
    getCartData,
    globalSettings,
    getContacts,
    getFilters
}), withNamespaces())(App));

